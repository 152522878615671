import { useState, useCallback } from 'react';
import Dialog from 'components/dialog/Dialog';
import { DialogContentProps, PaperProps } from '@mui/material';

interface IParameters {
    title?: string;
    content: JSX.Element | string;
    paperProps?: Partial<PaperProps>;
    dialogContentProps?: DialogContentProps;
    wrapContentIntoBox?: boolean;
    fullScreen?: boolean;
    fullWidth?: boolean;
    heightFitContent?: boolean;
    positionBottom?: boolean;
    keepMounted?: boolean;
    disablePortal?: boolean;
    closeOnBackdropClick?: boolean;
}

interface IReturn {
    show: () => void;
    hide: () => void;
    component: JSX.Element | null;
}

const useDialog = (parameters: IParameters): IReturn => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const show = useCallback(() => {
        setIsOpen(true);
    }, []);

    const hide = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleOnClose = () => {
        setIsOpen(false);
    };

    return {
        show,
        hide,
        component: (
            <Dialog
                isOpen={isOpen}
                onClose={handleOnClose}
                title={parameters.title}
                content={parameters.content}
                paperProps={parameters.paperProps}
                dialogContentProps={parameters.dialogContentProps}
                wrapContentIntoBox={parameters.wrapContentIntoBox}
                fullScreen={parameters.fullScreen}
                fullWidth={parameters.fullWidth}
                heightFitContent={parameters.heightFitContent}
                positionBottom={parameters.positionBottom}
                keepMounted={parameters.keepMounted}
                disablePortal={parameters.disablePortal}
                closeOnBackdropClick={parameters.closeOnBackdropClick ?? true}
            />
        ),
    };
};

export default useDialog;
